<!-- unauthorized.component.html -->
<div class="unauthorized-container">
  <mat-toolbar class="toolbar-main">
    <a href="https://www.ecolab.com">
      <img class="img" [src]="UnAuthICON" alt="Ecolab" />
    </a>
  </mat-toolbar>
  <div class="content-wrapper">
    <div class="error-code">401</div>
    <div class="content-overlay">
      <h1>Unauthorized User</h1>
      <p class="message">
        We can’t log you in at this time because your account does not have
        authorization to view the Total Value Delivered application.
      </p>
      <button mat-raised-button (click)="returnToMain()">
        Return to Ecolab.com
      </button>
    </div>
  </div>
  <div class="footer">
    <lib-one-customer-ui-footer></lib-one-customer-ui-footer>
  </div>
</div>
