{
  "TVD": {
    "TITLE": "Hello Angular with ngx-translate!",
    "BIC": "Best-In-Class Potential",
    "SELECT": "Change language",
    "ALLFILETERS": "All Filters",
    "SELECTACUSTOMER": "Select a Customer",
    "TIMERANGE": "Time Range",
    "STARTDATE": "Start Date",
    "ENDDATE": "End Date",
    "CLEAR": "Clear",
    "APPLY": "Apply",
    "CUSTOMER": "Customer",
    "SEARCH_CUSTOMERS": "Search Customers",
    "CUSTOMERNAMEISMANDATORY": "Customer Name is Mandatory",
    "CANCEL": "Cancel",
    "SITES": "Sites",
    "REDUCEDCOSTANDBENEFITS": "Increased Benefit & Reduced Cost Detail",
    "MODIFY": "Modify",
    "MYTOTALVALUEDELIVERED": "My Total Value Delivered",
    "TOTALVALUE": "Total Value",
    "TOTALVALUEDELIVERED": "Total Value Delivered",
    "TOTALVALUEPIPELINE": "Total Value Pipeline",
    "VALUEDELIVERED": "Value Delivered",
    "VALUEPIPELINE": "Value Pipeline",
    "INPIPELINE": "in the pipeline",
    "VIEWMORE": "View More",
    "VIEWLESS": "View Less",
    "VIEWDETAILS": "View Details",
    "PRIVACY_POLICY": "Privacy Policy",
    "TERMS_CONDITIONS": "Terms of Use",
    "VALUE_ACTIVITY_METRICS": "Value Activity Metrics",
    "FILTER_RESULTS": "Filter Results",
    "NUMBER_OF_FILTERS": "filters selected",
    "VIEW_BY": "View By:",
    "CUSTOMER_VIEW": "Customer View",
    "ECOLAB_VIEW": "Ecolab View",
    "CUSTOMER_DIVISION": "Division",
    "SELECT_ALL": "Select All",
    "CLEAR_ALL": "Clear All",
    "CLOSE": "Close",
    "OPTYPE": "Operating Type",
    "ADD_VALUE_ACTIVITY_METRIC": "Add Value Activity Metric(s)",
    "VALUE_ACTIVITY_METRIC": "Value Activity Metric",
    "TYPE_OF_METRIC": "Type of metric",
    "NAME_OF_METRIC": "Name of metric",
    "VALUE": "Value",
    "ADD_METRIC": "Add Metric",
    "REDUCED_COST_AND_BENEFITS_SUBHEAD": "Ecolab’s Increased Benefits and Reduced Cost value categories identified below are summed within the Cost & Benefits value category above",
    "HOW_WAS_THIS_CAL": "How was this calculated?",
    "DEFINITION": "Definition",
    "NULLSTATE_VAM_1": "No Value Activity Metrics Added",
    "NULLSTATE_VAM_2": "You currently have no value activity metrics added. Create your first to customize your view.",
    "ADD_DATA": "Add Data",
    "HOME": "Home"
  },
  "ONECUST": {
    "DEFINITION": "Definition",
    "INPIPELINE": "in the pipeline",
    "TOTALVALUEDELIVERED": "Total Value Delivered",
    "TOTALVALUEPIPELINE": "Total Value Pipeline",
    "VIEWMORE": "View More",
    "HOW_WAS_THIS_CAL": "How was this calculated?",
    "VIEWLESS": "View Less",
    "PRIVACYPOLICY": "Privacy Policy",
    "TERMS": "Terms of Use",
    "ECOLAB": "Ecolab",
    "LOGOUT": "Log Out",
    "TV": "Total Value",
    "TVD": "Total Value Delivered",
    "TVO": "Total Value Pipeline",
    "VD": "Value Delivered",
    "ETP": "Value Pipeline",
    "BIC": "Best-In-Class Potential",
    "VIEWDETAILS": "View Details",
    "VALUE_ACTIVITY_METRICS": "Value Activity Metrics",
    "MODIFY": "Modify",
    "NULLSTATE_VAM_1": "No Value Activity Metrics Added",
    "NULLSTATE_VAM_2": "You currently have no value activity metrics added. Create your first to customize your view.",
    "ADD_DATA": "Add Data",
    "ADD_VALUE_ACTIVITY_METRIC": "Add Value Activity Metric(s)",
    "VALUE_ACTIVITY_METRIC": "Value Activity Metric",
    "TYPE_OF_METRIC": "Type of metric",
    "NAME_OF_METRIC": "Name of metric",
    "VALUE": "Value",
    "APPLY": "Apply",
    "ADD_METRIC": "Add Metric"
  },
  "data": [
    {
      "level": "1",
      "data": [
        {
          "category": "Water",
          "HelpText": ["Reduce water usage and help optimize water quality"]
        },
        {
          "category": "Energy",
          "HelpText": [
            "Improve efficiency by reducing energy consumption:",
            " Reduce electrical energy through improved efficiency",
            " Reduce thermal energy through improved efficiency",
            " Help manage emissions to reduce carbon footprint."
          ]
        },
        {
          "category": "Waste",
          "HelpText": [
            "Help protect the environment by reducing operational waste:",
            "Reduce wastewater discharge.",
            "Reduce food, packaging, and other solid waste",
            "Help manage indoor air quality, odor, and dust control."
          ]
        },
        {
          "category": "Cost & Benefit",
          "HelpText": [
            "Increased benefit and reduced costs are identified within the \"Increased Benefit & Reduced Cost Detail\" section."
          ]
        }
      ]
    },
    {
      "level": "2",
      "data": [
        {
          "category": "Food Safety",
          "HelpText": ["Reduce the risk of illness-causing pathogens or pests"]
        },
        {
          "category": "Human Safety",
          "HelpText": [
            "Help promote environments that reduce risk to people, health, and safety"
          ]
        },
        {
          "category": "Water Safety",
          "HelpText": ["Reduce risk of illness from waterborne pathogens"]
        },
        {
          "category": "Delighted Guests",
          "HelpText": [
            "Promote cleaner, safer environments through targeted evaluations and customer-centric assessment"
          ]
        },
        {
          "category": "Brand",
          "HelpText": ["Protect equity and customer loyalty"]
        },
        {
          "category": "Quality",
          "HelpText": ["Help improve product quality          "]
        },
        {
          "category": "Regulatory Compliance",
          "HelpText": [
            "Support or enhance compliance driven by regulatory legislation  "
          ]
        },
        {
          "category": "Industry Compliance",
          "HelpText": [
            "Support or enhance compliance driven by industry standards or mandates"
          ]
        },
        {
          "category": "Asset Protection",
          "HelpText": ["Improve asset use or increase asset life"]
        },
        {
          "category": "Reliability",
          "HelpText": [
            "Reduce frequency or length of system, site or plant shutdowns"
          ]
        },
        {
          "category": "Capacity",
          "HelpText": ["Help increase system, site, or plant output  "]
        },
        {
          "category": "Labor Productivity",
          "HelpText": [
            "Streamline labor processes by helping to reduce time and effort required"
          ]
        },
        {
          "category": "Location Productivity",
          "HelpText": [
            "Help improve processes and reduce costs within site-specific activities"
          ]
        },
        {
          "category": "Product Optimization",
          "HelpText": ["Implement improvements related to producing products  "]
        },
        {
          "category": "Raw Material Optimization",
          "HelpText": ["Reduce raw material inputs used in production"]
        }
      ]
    }
  ]
}
