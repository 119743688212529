import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppAccessGuard } from './core/guards/app-access.guard';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';

const routes: Routes = [
  {
    path: '',
    canLoad: [AppAccessGuard],
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./features/tvd-setup/tvd-setup.module').then(
        (tvd) => tvd.TvdSetupModule,
      ),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedUserComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
